import * as React from 'react';
import Layout from '../../components/layout';
import Article from '../../components/article';
import { Seo } from '../../components/seo';

const FreelancingTips = ({ location }) => {
  const pageTitle = 'My 3 Best Tips for New Freelance Web Developers';
  return (
    <Layout pageTitle={pageTitle}>
      <Seo index={false} follow={false} pathname={location.pathname} />
      <Article>
        <h1 className="fancy">
          My 3 Best Tips for New Freelance Web Dev&shy;elop&shy;ers
        </h1>
        <p>
          Glad you enjoyed the post on getting your first freelance web
          development work. I’ll send you more tips for freelancing as I post
          them. In the meantime, here are <strong>my 3 favorite tips</strong> to
          keep in mind as you’re getting your footing.
        </p>
        <h2>1. Don’t Oversell</h2>
        <p>
          <em>Fake it ’til you make it!</em>
        </p>
        <p>
          You’ve probably heard this cliché parroted as justification for lying
          about what you can do.{' '}
          <strong>Don’t lie to people about what you can do for them</strong>.
          Your reputation is far more important than the confidence you project.
        </p>
        <p>
          You can, however, take this too far in the other direction. Don’t take
          me to mean you have to have experience in every single aspect of a job
          before you can take it. It’s alright to do some learning as you go. If
          someone asks you specifically if you have done X, you have not done X,
          and you tell them you <em>have</em> done X, that’s not “projecting
          confidence” or anything of the sort; it’s just lying.
        </p>
        <h2>2. Don’t Disqualify Yourself from a Job</h2>
        <p>
          If you’re forthcoming to your prospective clients about what you know
          and are capable of, there’s no need to talk yourself out of a job.
          Most of the people you apply with will disqualify you from their
          openings, so you’ll get plenty of that without contributing to it
          yourself.
        </p>
        <p>
          If you feel like you know 85 or 90% of what you need to know to
          complete a job and you’re confident you can pick up the rest along the
          way, go for it.
        </p>
        <h2>3. Follow Up</h2>
        <p>
          When you first start pursuing freelance work, you’ll be inclined to
          talk to everyone just a single time. If they really wanted to hire me,
          they would have said so, right? That’s not always the case. This is
          why you need to follow up.
        </p>
        <p>
          If you met someone new at a meet-up or at a conference, follow up with
          them to say it was nice to meet them. If you’re in contact with
          someone who may have work for you, follow up and then continue
          following up until they say they’ve hired someone else or cancelled
          the project. If they hired someone else, follow up with them in about
          3 months to see how the project is going. You’ll be shocked how much
          additional work these follow-ups will bring in.
        </p>
      </Article>
    </Layout>
  );
};
export default FreelancingTips;
