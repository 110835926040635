import * as React from 'react';
import { textContainer } from './text-container.module.css';

const Article = ({ pageTitle, children, width = '50em', style = {} }) => {
  const heading = <h1 className="fancy">{pageTitle}</h1>;
  return (
    <article className={textContainer} style={{ ...style, maxWidth: width }}>
      {pageTitle ? heading : null}
      {children}
    </article>
  );
};
export default Article;
